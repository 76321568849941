
















import {vxm} from "@/store";
import {Component, Vue} from 'vue-property-decorator';
import ErpcDashCaseView from "@/views/viewcomponents/ErpcDashCaseView.vue";
import RequestView from "@/views/viewcomponents/RequestView.vue";
import SmileyRater from "@/views/widgets/SmileyRater.vue";


@Component({
    components: {SmileyRater, ErpcDashCaseView, RequestView}
})

export default class ErpcDesk extends Vue {
    private ui = vxm.ui;

}
